import fetch from "cross-fetch";
import { config } from "./config";
import { UrlParams } from "./types";
import { v5 as uuid5 } from "uuid";
import { UrlSearchParamsParser } from "./urlSearchParamsParser";

function log(...data: any[]) {
  if (window.midastrackr.debug) {
    console.log("[MidasTrackr] " + new Date().toISOString() + " -", ...data);
  }
}

async function delayAsync(timeout = 500) {
  return new Promise(res => setTimeout(res, timeout));
}

async function getCTAButtons() {
  const TRIES = 100;

  for (let i = 1; i <= TRIES; i++) {
    log(`getting cta buttons try ${i} of ${TRIES}`);

    const btns: HTMLAnchorElement[] = [];

    document
      .querySelectorAll<HTMLAnchorElement>(window.midastrackr.btnSelector)
      .forEach(x => {
        if (x.href) {
          btns.push(x);
        }
      });

    if (!btns.length) {
      log(`no cta button found`);

      if (i < TRIES) {
        await delayAsync(25);
      }
    } else {
      log(`${btns.length} cta button(s) found`);
      return btns;
    }
  }

  return [];
}

async function postClick(
  urlParams: UrlParams,
  mclid: string,
  ip: string,
  shortMclid: string
) {
  log("posting click");

  const postClickUrl = new URL("tracking/click", config.api());
  const body = {
    projectId: window.midastrackr.pid,
    clickedAt: new Date().toISOString(),
    landingPageUrl: window.location.href,
    mclid,
    shortMclid,
    ip,
    ...urlParams
  };

  const postClickResponse = await fetch(postClickUrl, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" }
  });

  if (postClickResponse.status === 429) {
    log("too many requests");
    return "too-many-requests";
  }

  const data = await postClickResponse.text();

  log("post click response", data);

  if (!data) {
    log("could not post click");
    return;
  }

  return data;
}

async function getTags() {
  log("getting tags");

  const key = "midas-tags";
  const cachedTags = localStorage.getItem(key);

  if (cachedTags) {
    log("tags found in cache");
    return JSON.parse(cachedTags) as string[];
  }

  const getTagsUrl = new URL("salesplatform/tags", config.api());

  const getTagsResponse = await fetch(getTagsUrl).then(
    x => x.json() as Promise<string[]>
  );
  log("response", getTagsResponse);

  if (!getTagsResponse?.length) {
    log("could not get tags");
    return;
  }

  localStorage.setItem(key, JSON.stringify(getTagsResponse));

  return getTagsResponse;
}

function getUrlParams(): UrlParams {
  log("getting url params");

  const parser = new UrlSearchParamsParser(window.location.search);

  const hasQueryTestClick =
    parser.has("testClick") &&
    parser.get("testClick")?.toLowerCase() === "true";

  const hasCookieTestClick =
    window.document.cookie.indexOf("midas_test_click=true") !== -1;

  return {
    utmSource: parser.get("utm_source"),
    utmMedium: parser.get("utm_medium"),
    utmCampaign: parser.get("utm_campaign"),
    utmTerm: parser.get("utm_term"),
    utmContent: parser.get("utm_content"),
    gClid: parser.get("mgclid") || parser.get("gclid"),
    msClid: parser.get("msclid"),
    midasAdId: parser.get("midas_adid"),
    testClick: hasQueryTestClick || hasCookieTestClick
  };
}

function replaceCTAButtonUrl(
  btns: HTMLAnchorElement[],
  mclid: string,
  tags: string[]
) {
  log("replacing cta urls");

  for (let i = 0; i < btns.length; i++) {
    const btn = btns[i];
    const ctaUrl = btn.getAttribute("href")!;

    log(`btn ${i} old url`, ctaUrl);

    const url = new URL(ctaUrl);
    const originalUrl = new URL(ctaUrl);

    originalUrl.searchParams.forEach((_, key) => {
      if (tags.indexOf(key) !== -1) {
        url.searchParams.delete(key);
        url.searchParams.set(key, mclid);
      }
    });

    const newUrl = url.toString();

    log(`btn ${i} new url`, newUrl);

    btn.setAttribute("href", newUrl);
  }
}

function setCTAButtonListener(btns: HTMLAnchorElement[], mclid: string) {
  log("setting cta buttons listeners");

  function addCta() {
    const url = new URL("tracking/cta", config.api());
    url.searchParams.set("projectId", window.midastrackr.pid);
    url.searchParams.set("midasClid", mclid);

    fetch(url, { method: "POST" })
      .then(x => x.text())
      .then(response => {
        log("response", response);
      });
  }

  for (const btn of btns) {
    btn.removeEventListener("click", addCta);
    btn.addEventListener("click", addCta);
  }
}

async function getIp() {
  log("getting ip");

  const getIpUrl = new URL("network/ip", config.api());
  const response = await fetch(getIpUrl);
  const data = await response.text();

  log("get ip response", data);

  return data;
}

function getMclid(ip: string) {
  log("getting mclid");

  const mclid = uuid5(ip, window.midastrackr.pid);

  log("mclid =", mclid);

  return mclid;
}

function setupObserver(mclid: string, normalizedMclid: string, tags: string[]) {
  const observer = new MutationObserver(mutations => {
    for (const mutation of mutations) {
      mutation.addedNodes.forEach(node => {
        if (!(node instanceof HTMLElement)) return;

        const newCtaButtons: HTMLAnchorElement[] = [];

        if (node.matches(window.midastrackr.btnSelector)) {
          newCtaButtons.push(node as HTMLAnchorElement);
        }

        node.querySelectorAll(window.midastrackr.btnSelector).forEach(child => {
          newCtaButtons.push(child as HTMLAnchorElement);
        });

        for (const ctaButton of newCtaButtons) {
          setCTAButtonListener([ctaButton], mclid);
          replaceCTAButtonUrl([ctaButton], normalizedMclid, tags);
        }
      });
    }
  });

  observer.observe(document.body, {
    attributes: false,
    characterData: false,
    subtree: true,
    childList: true
  });
}

function toggleLockCTAButtons(btns: HTMLAnchorElement[], lock: boolean) {
  log(`${lock ? "locking" : "unlocking"} cta buttons`);

  for (const btn of btns) {
    if (lock) {
      btn.style.pointerEvents = "none";
    } else {
      btn.style.pointerEvents = "";
    }
  }
}

async function main() {
  log("starting");

  if (config.isBot()) {
    log(`bot detected, user-agent: ${navigator.userAgent}`);
    return;
  }

  if (!window.midastrackr?.pid || !window.midastrackr?.uid) {
    log("missing pid or uid");
    return;
  }

  window.midastrackr.btnSelector ||=
    "a[id*='midas-cta'], a[class*='midas-cta'], [id*='midas-cta'] a[href]";

  const firstCtaButtons = await getCTAButtons();

  toggleLockCTAButtons(firstCtaButtons, true);
  setTimeout(() => {
    toggleLockCTAButtons(firstCtaButtons, false);
  }, 1000);

  const [urlParams, ip, tags] = await Promise.all([
    getUrlParams(),
    getIp(),
    getTags()
  ]);

  if (!tags || !ip) return;

  const mclid = getMclid(ip);
  const normalizedMclid = mclid.replace(/-/g, "");
  const shortMclid = "mds" + normalizedMclid.slice(-10);

  toggleLockCTAButtons(firstCtaButtons, false);

  void postClick(urlParams, mclid, ip, shortMclid);

  const platformMclid = config.env() === "hml" ? shortMclid : normalizedMclid;

  const secondCtaButtons = await getCTAButtons();
  setupObserver(mclid, platformMclid, tags);

  setCTAButtonListener(secondCtaButtons, mclid);
  replaceCTAButtonUrl(secondCtaButtons, platformMclid, tags);

  window.midastrackr.done = true;
  window.midastrackr.mclid = mclid;
}

main();
